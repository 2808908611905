import { LOGIN, LOGOUT, REFRESH_FAILED, HYDRATE_USER } from 'config/actionTypes';

const initialState: {
  user?: Entities.User;
} = {
  user: undefined,
};

const userReducer = (
  state = initialState,
  action: ReturnType<ActionTypes.Auth.LoginAction> | ReturnType<ActionTypes.User.HydrateUserAction>,
) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        user: {
          ...action.payload,
          token: undefined,
        },
      };
    case LOGOUT:
      return {
        ...state,
        user: undefined,
      };
    case HYDRATE_USER:
      return {
        ...state,
        user: action.payload,
      };
    case REFRESH_FAILED:
      return {
        ...state,
        user: undefined,
      };
    default:
      return state;
  }
};

export default userReducer;
