import React, { useEffect, useState } from 'react';
import {
  HashRouter,
  Redirect,
  Route as ReactRouterRoute,
  RouteProps,
  Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { refreshSuccessAction, refreshFailedAction } from 'actions/auth';
import { hydrateUserAction } from 'actions/user';
import './scss/style.scss';
import { RootState } from 'reducers/store';
import { CSpinner } from '@coreui/react';
import { getAdminDetailsApi, refreshApi } from 'api/authApi';
// Containers
const TheLayout = React.lazy(() => import('main/TheLayout'));
// Pages
const Login = React.lazy(() => import('screens/auth/login/Login'));
const Page404 = React.lazy(() => import('screens/auth/page404/Page404'));
const Page500 = React.lazy(() => import('screens/auth/page500/Page500'));
const ForgotPassword = React.lazy(() => import('screens/auth/forgotPassword'));
const ResetPassword = React.lazy(() => import('screens/auth/resetPassword'));

class Route extends ReactRouterRoute<RouteProps & { name: string }> {}

const suspenseFallback = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

const App = (props: ScreenProps.App) => {
  const [initialRoute] = useState(window.location.hash);
  const [loading, setLoading] = useState(true);

  /** LIFECYCLE HOOKS */
  useEffect(() => {
    refreshApi()
      .then((token) => {
        props.refreshSuccessAction(token);
        return getAdminDetailsApi();
      })
      .then((user) => {
        props.hydrateUserAction(user);
      })
      .catch(() => {
        props.refreshFailedAction();
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="c-app c-default-layout flex-row align-items-center justify-content-center">
        <CSpinner size="lg" color="info" />
      </div>
    );
  }

  if (props.loggedIn) {
    return (
      <HashRouter>
        <React.Suspense fallback={suspenseFallback}>
          <Switch>
            <Route exact path="/404" name="Page 404" render={() => <Page404 />} />
            <Route exact path="/500" name="Page 500" render={() => <Page500 />} />
            <Route path="/" name="Home" render={() => <TheLayout />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }

  return (
    <HashRouter>
      <React.Suspense fallback={suspenseFallback}>
        <Switch>
          <Route
            exact
            path="/"
            name="Home"
            render={() => (
              <Login
                initialRoute={initialRoute}
                register={() => window.location.assign('/#/register')}
              />
            )}
          />
          <Route
            path="/forgot_password"
            exact
            name="Forgot Password"
            render={() => <ForgotPassword />}
          />
          <Route
            path="/reset_password"
            exact
            name="Reset Password"
            render={() => <ResetPassword />}
          />
          <Redirect to="/" />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loggedIn: state.auth.loggedIn,
  };
};
export type AppReduxStateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = {
  refreshSuccessAction,
  hydrateUserAction,
  refreshFailedAction,
};
export type AppReduxActionProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(App);
