export const LOG = false;
export const DEV_MODE = false;
export const DEV_URL = 'http://jsss-payroll-backend/api/v1';
export const PROD_URL = 'https://api.payroll.jssschool.com/api/v1';
export const BASE_URL = DEV_MODE ? DEV_URL : PROD_URL;
export const ADMIN_TOKEN_KEY = DEV_MODE ? 'DEV_ADMIN_TOKEN_KEY' : 'ADMIN_TOKEN_KEY';
export const INITIATE_FORGOT_PASSWORD_KEY = 'INITIATE_FORGOT_PASSWORD_KEY';
export const PAGINATION_DEFAULT_PAGE_SIZE = 10;
export const VERSION = '0.0.1';
export const MIN_PASSWORD_LENGTH = 6;
export const GENDERS = [
  {
    value: 'Male',
    label: 'Male',
  },
  {
    value: 'Female',
    label: 'Female',
  },
];
export const MARITAL_STATUS = [
  {
    value: 'Married',
    label: 'Married',
  },
  {
    value: 'Unmarried',
    label: 'Unmarried',
  },
];
export const MONTHS = [
  {
    value: 1,
    label: 'January',
  },
  {
    value: 2,
    label: 'February',
  },
  {
    value: 3,
    label: 'March',
  },
  {
    value: 4,
    label: 'April',
  },
  {
    value: 5,
    label: 'May',
  },
  {
    value: 6,
    label: 'June',
  },
  {
    value: 7,
    label: 'July',
  },
  {
    value: 8,
    label: 'August',
  },
  {
    value: 9,
    label: 'September',
  },
  {
    value: 10,
    label: 'October',
  },
  {
    value: 11,
    label: 'November',
  },
  {
    value: 12,
    label: 'December',
  },
];
