import { POST, GET } from 'utilities/AxiosHelpers';

export const loginApi = (email: string, password: string) => {
  return POST<Entities.User>('admin/login', { email, password });
};

export const logoutApi = () => {
  return POST<string>('admin/logout');
};

export const refreshApi = () => {
  return POST<string>('admin/refresh');
};

export const getAdminDetailsApi = () => {
  return GET<Entities.User>('admin/auth_user');
};

export const initiateForgotPasswordApi = (mobile: string) => {
  return POST<string>('admin/initiate_change_guest_password', { mobile });
};

export const verifyOtpApi = (mobile: string, otp: string) => {
  return POST<{ token: string }>('admin/otp/verify', {
    mobile,
    otp,
    callback: 'GENERATE_FORGOT_TOKEN',
  });
};

export const resetPasswordApi = (
  mobile: string,
  password: string,
  password_confirmation: string,
  token: string,
) => {
  // console.log('params', mobile, password, password_confirmation, token);
  return POST<string>('admin/change_guest_password', {
    username: mobile,
    password,
    password_confirmation,
    token,
  });
};

export const changePasswordApi = (data: {
  old_password?: string;
  password?: string;
  password_confirmation?: string;
}) => {
  return POST<string>('admin/change_user_password', data);
};
